.table-preview {
  $note-badge-width: 10px;
  $status-icon-width: 20px;

  &-head-cell,
  &-body-cell {
    width: 58px;

    &.cell {
      &-name {
        width: 185px;
      }

      &-icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
        padding: 0;

        &-mib {
          width: 18px;
        }

        &-bde {
          width: 21px;
        }

        &-note {
          width: 10px;
          margin-right: auto;
        }

        &-link {
          width: 12px;
          margin-right: 0;
          margin-left: 10px;
        }

        &-alarm {
          width: 16px;

          i {
            width: 16px;
            height: 16px;
            border-radius: 3px;
          }
        }
      }

      &-data {
        text-align: right;
        margin-left: 10px;
      }
    }
  }

  &-icon {
    &-note {
      width: $note-badge-width;
      height: $note-badge-width;
      background-color: $coral;
      border-radius: 50%;
    }

    &-text {
      width: min-content;
      color: $gun-metal;
      font-size: 10px;
      font-weight: bold;
      display: inline-flex;

      &.wave-animation {
        span {
          animation: wave-text $text-animation-duration ease-in-out infinite;

          &:nth-child(2) {
            animation-delay: 150ms;
          }

          &:nth-child(3) {
            animation-delay: calc(150ms * 2);
          }
        }

        @keyframes wave-text {
          0% {
            transform: translateY(0);
          }
          25% {
            transform: translateY(-6px);
          }
          70% {
            transform: translateY(0);
          }
        }
      }

      &.active {
        color: $coral;
      }
    }

    &-config {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      display: block;
      width: 20px;
      height: 10px;

      @include media-breakpoint-down(lg) {
        left: 0px;
      }
    }

    &-link {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover,
      &:active {
        .table-preview-icon-link-label {
          color: $coral;
        }

        i:before {
          background-color: $coral;
        }
      }

      &.menu-open .table-preview-icon-link-menu {
        display: flex;
      }

      &-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        bottom: -10px;
        right: 20px;
        width: max-content;
        max-width: 300px;
        height: fit-content;
        max-height: 95px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 5px;
        padding: 12px 15px;

        a {
          display: inline-block;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &:hover,
          &:active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-trend {
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);

    &-up,
    &-down {
      display: block;
      width: 10px;
      height: 5px;
    }

    &-up {
      background-color: $success;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }

    &-down {
      background-color: $error;
      clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
    }
  }

  &-body-row {
    &:not(:hover) {

      .table-preview-icon-config {
        opacity: 0;
        pointer-events: none;

      }
    }
  }
}